<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailForm" title="상세">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="popupParam.conductId" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="실사명"
                  name="conductName"
                  v-model="data.conductName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  :required="true"
                  :editable="editable"
                  label="실사 기간"
                  :range="true"
                  name="conductDts"
                  v-model="data.conductDts"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-dept type="edit" :editable="editable" label="담당부서" :required="true" name="deptCd" v-model="data.deptCd" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
                <c-plant
                  :required="true"
                  :editable="editable"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="비고"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="grid"
            title="실사대상 자재목록"
            :columns="grid.columns"
            :data="data.conductItem"
            :gridHeight="grid.height"
            :editable="editable"
            :hideBottom="false"
            :usePaging="true"
            :isExcelDown="true"
            :filtering="true"
            :isFullScreen="true"
            :columnSetting="false"
            topBorderClass="topcolor-orange"
            selection="multiple"
            rowKey="conductItemId"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="대상 자재추가" icon="add" @btnClicked="addrow" v-if="editable && popupParam.conductId" />
                <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable && popupParam.conductId" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'spare-actual-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        conductId: '',
        plantCd: null,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: false,
      saveUrl: transactionConfig.sp.conduct.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      itemDeleteUrl: '',
      isSave: false,
      isdelete: false,
      data: {
        plantCd: null,  // 사업장코드
        conductId: '',  // 실사 일련번호
        conductName: '',  // 실사명
        conductStartDt: '',  // 실사 시작일
        conductEndDt: '',  // 실사 종료일
        deptCd: '',  // 담당부서
        remark: '',  // 비고
        conductItem: [], // 실사자재목록
        conductDts: [],
      },
      grid: {
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'materialStandard',
            field: 'materialStandard',
            label: '규격',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'materialQuality',
            field: 'materialQuality',
            label: '재질',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          {
            name: 'realStock',
            field: 'realStock',
            label: '재고량',
            type: 'cost',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'safetyStock',
            field: 'safetyStock',
            label: '안전 재고량',
            type: 'cost',
            style: 'width:180px',
            sortable: true,
          },
          {
            setHeader: true,
            name: 'conductDt',
            field: 'conductDt',
            label: '실사 일자',
            type: 'date',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'conductStock',
            field: 'conductStock',
            label: '실사 재고량',
            type: 'number',
            style: 'width:250px',
            sortable: true,
          },
        ],
        data: [],
        height: '400px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.sp.conduct.get.url;
      this.insertUrl = transactionConfig.sp.conduct.insert.url;
      this.updateUrl = transactionConfig.sp.conduct.update.url;
      this.deleteUrl = transactionConfig.sp.conduct.delete.url;
      this.itemDeleteUrl = transactionConfig.sp.conduct.itemdelete.url;
      
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.conductId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {conductId: this.popupParam.conductId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.conductId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              let saveData = this.data.conductItem.filter( x => {
                return x.editFlag == 'C' || x.editFlag == 'U'
              });
              this.data.conductItem = saveData;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '실사자재목록도 삭제됩니다.\r\n삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.conductId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.conductId = result.data.conductId
      this.getDetail();
    },
    addrow() {
      this.popupOptions.title = '실사 대상자재 검색'; // 실사 대상자재 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.data.conductItem, {materialCd: item.materialCd}) === -1 ) {
            this.data.conductItem.push({
              conductId: this.popupParam.conductId,
              conductItemId: '',
              materialCd: item.materialCd, 
              materialName: item.materialName, 
              conductDt: this.$comm.getToday(),  // 실사일
              conductStock: '',  // 실사재고량
              calcStock: '',  // 재고 보정 수량
              materialUnitName: item.materialUnitName,
              materialStandard: item.materialStandard,
              materialQuality: item.materialQuality,
              safetyStock: item.safetyStock,
              realStock: item.realStock,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              chgUserId: this.$store.getters.user.userId,  // 수정자 ID
              editFlag: 'C'
            });
          }
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.itemDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.data.conductItem = this.$_.reject(this.data.conductItem, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    tableDataChange(props) {
      if (props.row['realStock'] > 0 && props.row['conductStock'] > 0) {
        this.$set(props.row, 'calcStock', props.row['conductStock'] - props.row['realStock']);
      }
    },
  }
};
</script>